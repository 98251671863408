<!--
 * @Author: 月魂
 * @Date: 2021-03-23 09:49:18
 * @LastEditTime: 2021-06-24 15:41:56
 * @LastEditors: 月魂
 * @Description: 
 * @FilePath: \stoms-mp\src\views\Card.vue
-->
<template>
  <div>
    <van-nav-bar
      title="观剧"
      fixed
      placeholder
    />
    <div class="cont">
      <div class="list">
        <div class="cardBox" v-for="item in list" :key="item.id">
          <div class="card" @click="handleClick(item)">
            <img :src="item.image_url" class="cardImg">
          </div>
          <div class="bottom">
            <div class="left">
              <!-- <div>
                <span>卡种类型：期卡</span>
              </div> -->
              <div v-show="item.originalPrice" style="color: #555">
                <span>原价：</span>
                <span style="text-decoration: line-through; font-size: 14px">￥{{ item.originalPrice }}</span>
              </div>
              <div class="price">
                <span>售价：</span>
                <span>￥{{ item.price }}</span>
              </div>
            </div>
            <div class="right">
              <van-button v-show="item.status === 0" class="apply" round size="small" @click="handleClick(item)" disabled color="linear-gradient(to right, #ff6034, #ee0a24)">
                敬请期待
              </van-button>
              <van-button v-show="item.status === 2 || item.sales_volume === item.stock" class="apply" round size="small" disabled color="linear-gradient(to right, #525252, #080808)">
                已售罄
              </van-button>
              <van-button v-show="item.status === 1" class="apply" round size="small" @click="handleClick(item)" color="linear-gradient(to right, #ff6034, #ee0a24)">
                立即申领
              </van-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <van-tabbar route>
      <!-- <van-tabbar-item replace to="/" icon="home-o">购票</van-tabbar-item> -->
      <van-tabbar-item replace to="/" icon="idcard">购卡</van-tabbar-item>
      <!-- <van-tabbar-item replace to="/brand" icon="friends-o">品牌</van-tabbar-item> -->
      <van-tabbar-item replace to="/my" icon="contact">我的</van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
import Vue from 'vue';
import { getCardList } from '@/api/card'
import { NavBar, Tabbar, TabbarItem, Button } from 'vant';
Vue.use(NavBar).use(Tabbar).use(TabbarItem).use(Button);
export default {
  name: 'Card',
  data() {
    return {
      pagination: {
        pageSize: -1,
        pageNumber: 1,
        status: '0,1,2',
        shelf: 1
      },
      list: []
    }
  },
  mounted() {
    getCardList(this.pagination).then(res => {
      if (res.code !== 0) return
      let init = []
      let onSale = []
      let finish = []
      res.data.records.forEach(item => {
        Vue.set(item, 'isFlipped', false)
        if (item.status === 0) {
          init.push(item)
        } else if (item.status === 1 && item.sales_volume !== item.stock) {
          onSale.push(item)
        } else {
          finish.push(item)
        }
      })
      this.list = [...onSale, ...init, ...finish]
    })
  },
  methods: {
    flipCard(item) {
      item.isFlipped = !item.isFlipped
    },
    handleClick(item) {
      if (item.status !== 1) return
      this.$router.push(`/card/detail/${item.id}`);
    },
  },
}
</script>

<style lang="less" scoped>
.cont {
  padding-bottom: 60px;
  background-color: #f9f9f9;
}
.list {
  .cardBox {
    box-sizing: border-box;
    border-radius: 20px;
    margin-top: 10px;
    background-color: #fff;
    padding-bottom: 10px;
    .card {
      box-sizing: border-box;
      border-radius: 20px;
      position: relative;
      width: 355px;
      height: 150px;
      overflow: hidden;
      .cardImg {
        border-radius: 20px;
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 355px;
        height: 150px;
      }
    }
    .bottom {
      margin-top: 10px;
      display: flex;
      justify-content: space-between;
      padding: 10px;
      .left {
        .price {
          color: red;
        }
      }
      .right {
        .apply {
          padding: 0 20px;
        }
      }
    }
  }
}
</style>
